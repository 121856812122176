module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-168639833-1","anonymize":true,"head":false,"respectDNT":false,"exclude":[],"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Bassi Service","short_name":"bassiservice","start_url":"/","background_color":"#181818","theme_color":"#06a6a6","display":"minimal-ui","icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"db8a7e5da250ef355d05a77bc0881d9e"},
    },{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-iubenda-cookie-footer/gatsby-browser.js'),
      options: {"plugins":[],"iubendaOptions":{"consentOnContinuedBrowsing":false,"whitelabel":false,"lang":"it","siteId":1927637,"cookiePolicyId":24429738,"cookiePolicyUrl":"https://bassiservice.it/cookies","cookiePolicyInOtherWindow":true,"banner":{"acceptButtonDisplay":true,"customizeButtonDisplay":false,"position":"float-bottom-center","brandBackgroundColor":"#181818","brandTextColor":"#181818","acceptButtonColor":"#06a6a6","acceptButtonCaptionColor":"#ffffff","customizeButtonColor":"#181818","customizeButtonCaptionColor":"#ffffff","rejectButtonColor":"#06a6a6","rejectButtonCaptionColor":"#ffffff","textColor":"#ffffff","backgroundColor":"#181818","logo":"https://bassiservice.it/logo-banner-negative.png","closeButtonDisplay":false}}},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
